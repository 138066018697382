import BaseBean from "@/utils/BaseBean";

export interface IWbListDataObj {
    utilInst:WbListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
}

export default class WbListUtil extends BaseBean{
    public dataObj:IWbListDataObj

    constructor(proxy:any,dataObj:IWbListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}