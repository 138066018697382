import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide} from 'vue';
import WbListUtil,{IWbListDataObj} from './wbListUtil';
export default defineComponent ({
    name: 'WbList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IWbListDataObj=reactive<IWbListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                isShowFixCol:false,
                queryParam: {},
                modelMethod: utils.ToolsProviderApi.buildUrl('/wb/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new WbListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------

        return{
            ...toRefs(dataObj)
        }
    }
});